import React, { useState, useEffect } from 'react'
import "../admin/users/allUsers.css";
import { fetchUserData } from '../../store/thunk/admin/adminRequest';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from "react-cookie";
import CircularProgress from '@mui/material/CircularProgress';
import { setMessage } from '../../store/thunk/error/errorMessage';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import {Box} from '@mui/material';
import "./users.css";

function Users() {
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0
      })
    const [cookies, removeCookie] = useCookies(['token']);
    const dispatch = useDispatch();
    const admin = useSelector(state => state.users);
    const navigate = useNavigate();
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
      });

    const fetch_all_users = () => {
        setPageState(old => ({ ...old, isLoading: true }));
        dispatch(fetchUserData({
            "get": [
                {
                    "key": "fetch_users",
                    "limit": paginationModel.pageSize,
                    "offset": paginationModel.pageSize * paginationModel.page
                }
            ],
            token: cookies.token
        })).then((res) => {
            console.log("res",res);
            if (typeof res?.type !== "undefined" && res.type.toString() === "users/users/fulfilled") {
                setIsLoading(false);
                setPageState(old => ({ ...old, isLoading: false, data: res.payload.fetch_users.users, total: res.payload.fetch_users.total }))
            } else if (typeof res?.type !== "undefined" && res.type.toString() === "users/users/rejected" && res.payload.response.status === 401) {
                setIsLoading(false);
                dispatch(setMessage({
                    status: true,
                    message: res?.payload?.response?.data?.message,
                    type: "error"
                }));
                localStorage.clear();
                removeCookie('token', '/');
                navigate('/login');
                window.location.reload();
            } 
            else if (typeof res?.type !== "undefined" && res.type.toString() === "users/users/rejected") {
                setPageState(old => ({ ...old, isLoading: false }));
                dispatch(setMessage({
                    status: true,
                    message: res?.payload?.response?.data?.message,
                    type: "error"
                }));
                
            }
        })
    }
    useEffect(() => {
        if (typeof cookies.token !== "undefined" && cookies.token !== "") {
            fetch_all_users();
        } else {
            navigate('/login');
        }
    }, [paginationModel]);
    useEffect(() => {
        if (typeof cookies.token !== "undefined" && cookies.token !== "") {
            fetch_all_users();
        } else {
            navigate('/login');
        }
    }, []);
    useEffect(() => {
        console.log("page State",pageState);
    }, [pageState]);

    const columns = [
        { 
          field: 'id', 
          headerName: 'ID', 
          label: 'id', 
          minWidth: 50
        },
        { 
          field: 'name', 
          headerName: 'NAME', 
          label: 'Name', 
          minWidth: 100 
        },
        {
            field: 'email',
            headerName: 'EMAIL',
            label: 'Email',
            minWidth: 100,
            align: 'right',
        },
        {
            field: 'user_id',
            headerName: 'USER ID',
            label: 'User Id',
            minWidth: 170,
            align: 'right'
        },
        {
            field: 'phone',
            headerName: 'PHONE',
            label: 'phone',
            minWidth: 100,
            align: 'right'
        },
        {
            field: 'type',
            headerName: 'TYPE',
            label: 'Type',
            minWidth: 70,
            align: 'left'
        },
        {
            field: 'user_wallet_balance',
            headerName: 'USER WALLET BALANCE',
            label: 'User Balance',
            minWidth: 150,
            align: 'right'
        },
        {
            field: 'email_verified_at',
            headerName: 'EMAIL VERIFIED AT',
            label: 'Email Verified',
            minWidth: 170,
            align: 'right',
        },
        {
            field: 'created_at',
            headerName: 'CREATED AT',
            label: 'Created At',
            minWidth: 170,
            align: 'right',
        },
        {
            field: 'updated_at',
            headerName: 'UPDATED AT',
            label: 'Updated At',
            minWidth: 170,
            align: 'right'
        }
    ];
    function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarExport 
            printOptions={{
                hideFooter: true,
                hideToolbar: true,
              }}
            />
          </GridToolbarContainer>
        );
      }

    return (
        <>
            {isLoading &&
                <Box className="homeLoader" sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>}
            <div style={{ height: '100%', width: '100%' }}>
                <Box sx={{ height: '80%', paddingX:'10px'}} >
                    <DataGrid
                        getRowHeight={() => '500'}
                        rows={pageState.data}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        pageSizeOptions={[5,10,50,100]}
                        pagination
                        pageSize = {paginationModel.pageSize}
                        paginationMode="server"
                        onPageSizeChange={(newPageSize) => setPaginationModel(old => ({ ...old, pageSize: newPageSize }))}
                        columns={columns}
                        onPaginationModelChange={setPaginationModel}
                        slots={{
                            toolbar: CustomToolbar,
                          }}
                          disableRowSelectionOnClick
                    />
                </Box>
            </div>
        </>
    )
}

export default Users;