import { configureStore  } from '@reduxjs/toolkit';
import loginReducer from './thunk/login/loginSlice.js';
import usersReducer from './thunk/admin/usersSlice.js';
import ordersReducer from './thunk/admin/ordersSlice.js';
import cartReducer from './thunk/cart/cartSlice.js';
import productReducer from './thunk/product/productSlice.js';
import pincodeReducer from './thunk/pincode/pincodeSlice.js';
import orderReducer from './thunk/order/orderSlice.js';
import walletReducer from './thunk/wallet/walletSlice.js';
import accountReducer from './thunk/account/accountSlice.js';
import orderDetailReducer from './thunk/orderDetails/orderDetailSlice.js';
import selectedOrdersReducer from './thunk/selectedOrders/selectedOrdersSlice.js';
import errorMessageReducer from './thunk/error/errorMessage.js';

// const customizedMiddleware = getDefaultMiddleware({
//   serializableCheck: false
// })
export default configureStore({
  reducer: {
    login:loginReducer,
    users:usersReducer,
    orders:ordersReducer,
    error:errorMessageReducer,
    order:orderReducer,
    orderDetail:orderDetailReducer,
    wallet:walletReducer,
    account:accountReducer,
    selectedorders:selectedOrdersReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})