import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import data from "../../../config.json";


const getAllOrders = createAsyncThunk(
    'users/orders',
    async (postData, thunkAPI) => {
        console.log('[fetchAllOrders] postData', postData !== undefined ? postData : '');
        try {
            const url = data?.api?.order_get;
            const token = typeof postData?.token !== "undefined" && postData.token
            const config = {
                headers: { 
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin":  'https://recyclebee.ca/api/user/checkout',
                    "Access-Control-Allow-Headers": "Content-Type, Authorization",
                }
            };
            if (url) {
                const response = await axios.post(url, postData, config)
                console.log('[fetchAllOrders] response', response);
                if(response.status === 200 && response.data.status === true ){
                    return thunkAPI.fulfillWithValue(response.data.data);
                }else{
                    throw "something went wrong"
                }
            } else{
                throw "url not found"
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    },
)
export default getAllOrders;