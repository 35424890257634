import React, { useState, useEffect } from 'react'
import "../admin/users/allUsers.css";
import { fetchUserData } from '../../store/thunk/admin/adminRequest';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from "react-cookie";
import CircularProgress from '@mui/material/CircularProgress';
import { setMessage } from '../../store/thunk/error/errorMessage';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import {Box} from '@mui/material';
import "./users.css";

function Orders() {
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0
      })
    const [cookies, removeCookie] = useCookies(['token']);
    const dispatch = useDispatch();
    const admin = useSelector(state => state.users);
    const navigate = useNavigate();
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
      });

    const fetch_all_orders = () => {
        setPageState(old => ({ ...old, isLoading: true }));
        dispatch(fetchUserData({
            "get": [
                {
                    "key": "fetch_orders_with_details",
                    "limit": paginationModel.pageSize,
                    "offset": paginationModel.pageSize * paginationModel.page
                }
            ],
            token: cookies.token
        })).then((res) => {
            console.log("res",res);
            if (typeof res?.type !== "undefined" && res.type.toString() === "users/users/fulfilled") {
                setIsLoading(false);
                setPageState(old => ({ ...old, isLoading: false, data: res.payload.fetch_orders_with_details.orders, total: res.payload.fetch_orders_with_details.total }))
            } 
            else if (typeof res?.type !== "undefined" && res.type.toString() === "users/users/rejected" && res.payload.response.status === 401) {
                setIsLoading(false);
                dispatch(setMessage({
                    status: true,
                    message: res?.payload?.response?.data?.message,
                    type: "error"
                }));
                localStorage.clear();
                removeCookie('token', '/');
                navigate('/login');
                window.location.reload();
            } 
            else if (typeof res?.type !== "undefined" && res.type.toString() === "users/users/rejected") {
                setPageState(old => ({ ...old, isLoading: false }));
                dispatch(setMessage({
                    status: true,
                    message: res?.payload?.response?.data?.message,
                    type: "error"
                }));
                
            }
        })
    }
    useEffect(() => {
        if (typeof cookies.token !== "undefined" && cookies.token !== "") {
            fetch_all_orders();
        } else {
            navigate('/login');
        }
    }, [paginationModel]);
    useEffect(() => {
        if (typeof cookies.token !== "undefined" && cookies.token !== "") {
            fetch_all_orders();
        } else {
            navigate('/login');
        }
    }, []);
    useEffect(() => {
        console.log("page State",pageState);
    }, [pageState]);

    const columns = [
        { 
          field: 'order_id', 
          headerName: 'ORDER ID', 
          label: 'order_id', 
          minWidth: 50 
        },
        { 
          field: 'order_detail_id', 
          headerName: 'ORDER DETAIL ID', 
          label: 'order_detail_id', 
          minWidth: 100 
        },
        { 
          field: 'customer_name', 
          headerName: 'CUSTOMER NAME', 
          label: 'Name', 
          minWidth: 100 
        },
        {
            field: 'nice_id',
            headerName: 'UNIQUE ID',
            label: 'nice_id',
            minWidth: 100,
            align: 'right',
        },
        {
            field: 'order_mobile',
            headerName: 'USER MOBILE',
            label: 'order_mobile',
            minWidth: 170,
            align: 'right'
        },
        {
            field: 'order_postal_code',
            headerName: 'POSTAL CODE',
            label: 'order_postal_code',
            minWidth: 100,
            align: 'right'
        },
        {
            field: 'order_address',
            headerName: 'ADDRESS',
            label: 'Type',
            minWidth: 70,
            align: 'left'
        },
        {
            field: 'order_city',
            headerName: 'CITY',
            label: 'order_city',
            minWidth: 150,
            align: 'right'
        },
        {
            field: 'payment_status',
            headerName: 'PAYMENT STATUS',
            label: 'payment_status',
            minWidth: 170,
            align: 'right',
        },
        {
            field: 'order_created_at',
            headerName: 'ORDER CREATED AT',
            label: 'order_created_at',
            minWidth: 170,
            align: 'right',
        },
        {
            field: 'order_last_updated_at',
            headerName: 'ORDER LAST UPDATED',
            label: 'Updated At',
            minWidth: 170,
            align: 'right'
        },
        {
            field: 'product_requested_date',
            headerName: 'PRODUCT REQUESTED DATE',
            label: 'product_requested_date',
            minWidth: 170,
            align: 'right'
        },
        {
            field: 'product_approved_date',
            headerName: 'PRODUCT APPROVED DATE',
            label: 'product_approved_date',
            minWidth: 170,
            align: 'right'
        },
        {
            field: 'product_processing_date',
            headerName: 'PRODUCT PROCESSING DATE',
            label: 'Updated At',
            minWidth: 170,
            align: 'right'
        },
        {
            field: 'product_completed_date',
            headerName: 'ORDER COMPLETE DATE',
            label: 'Updated At',
            minWidth: 170,
            align: 'right'
        },
        {
            field: 'product_cancelled_date',
            headerName: 'ORDER CANCELLED DATE',
            label: 'product_cancelled_date',
            minWidth: 170,
            align: 'right'
        },
        {
            field: 'product_rejected_date',
            headerName: 'ORDER REJECTED DATE',
            label: 'product_rejected_date',
            minWidth: 170,
            align: 'right'
        },
    ];
    function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarExport 
            printOptions={{
                hideFooter: true,
                hideToolbar: true,
              }}
            />
          </GridToolbarContainer>
        );
      }
    //   const convertedData = ()=>{
    //     const data = pageState.data;
    //     data.map(dt=>{
    //         dt
    //     })
    //   }

    return (
        <>
            {isLoading &&
                <Box className="homeLoader" sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>}
            <div style={{ height: '100%', width: '100%' }}>
                <Box sx={{ height: '80%', paddingX:'10px'}} >
                    <DataGrid
                        getRowId={(row) => row.order_detail_id}
                        getRowHeight={() => '500'}
                        rows={pageState.data}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        pageSizeOptions={[5,10,50,100]}
                        pagination
                        pageSize = {paginationModel.pageSize}
                        paginationMode="server"
                        onPageSizeChange={(newPageSize) => setPaginationModel(old => ({ ...old, pageSize: newPageSize }))}
                        columns={columns}
                        onPaginationModelChange={setPaginationModel}
                        slots={{
                            toolbar: CustomToolbar,
                          }}
                          disableRowSelectionOnClick
                    />
                </Box>
            </div>
        </>
    )
}

export default Orders;