import React, { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import "./allUsers.css";
import { fetchUserData } from '../../../store/thunk/admin/adminRequest';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from "react-cookie";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import updateUser from '../../../store/thunk/update-user/updateUserRequest';
import { setMessage } from '../../../store/thunk/error/errorMessage';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';

function AllUsers() {
    const [page, setPage] = React.useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [userDetail, setUserDetail] = useState({});
    const [cookies,removeCookie] = useCookies(['token']);
    const dispatch = useDispatch();
    const admin = useSelector(state => state.users);
    const [adminData, setAdminData] = useState(admin);
    const [userStatus, setUserStatus] = useState();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const navigate = useNavigate();
    

    function convertTime(value) {
        const convertedDate = <Moment subtract={{ hours: 3 }}>{value}</Moment>;
        return convertedDate
      }

    const fetch_all_orders = ()=>{
        dispatch(fetchUserData({
            "get": [
                {
                    "key": "fetch_users",
                    "limit": rowsPerPage,
                    "offset": rowsPerPage * page
                }
            ],
            token: cookies.token
        })).then((res) => {
            if (typeof res?.type !== "undefined" && res.type.toString() === "users/users/fulfilled") {
                setIsLoading(false);
            } else if (typeof res?.type !== "undefined" && res.type.toString() === "users/users/rejected") {
                setIsLoading(false);
                dispatch(setMessage({
                    status: true,
                    message: res?.payload?.response?.data?.message,
                    type: "error"
                }));
                localStorage.clear();
                removeCookie('token', '/');
                navigate('/login');
                window.location.reload();
            }
        })
    }
    useEffect(() => {
        if(typeof cookies.token !== "undefined" && cookies.token !== ""){
            fetch_all_orders();
        }else{
            navigate('/login');
        }
    }, []);
    useEffect(() => {
        fetch_all_orders();
    }, [rowsPerPage,page]);
    useEffect(() => {
        setAdminData(admin);
    }, [admin]);

    const columns = [
        { id: 'id', label: 'id', minWidth: 50 },
        { id: 'name', label: 'Name', minWidth: 100 },
        {
            id: 'email',
            label: 'Email',
            minWidth: 100,
            align: 'right',
        },
        {
            id: 'user_id',
            label: 'User Id',
            minWidth: 170,
            align: 'right'
        },
        {
            id: 'phone',
            label: 'phone',
            minWidth: 100,
            align: 'right'
        },
        {
            id: 'type',
            label: 'Type',
            minWidth: 150,
            align: 'right'
        },
        {
            id: 'user_wallet_balance',
            label: 'User Balance',
            minWidth: 150,
            align: 'right'
        },
        {
            id: 'email_verified_at',
            label: 'Email Verified',
            minWidth: 170,
            align: 'right',
        },
        {
            id: 'created_at',
            label: 'Created At',
            minWidth: 170,
            align: 'right',
        },
        {
            id: 'updated_at',
            label: 'Updated At',
            minWidth: 170,
            align: 'right'
        },
        {
            id: 'action',
            label: 'Action',
            minWidth: 120,
            align: 'right'
        },
    ];

    function createData(id, name, email, user_id, phone, type, status, user_wallet_balance, email_verified_at, created_at, updated_at) {
        return { id, name, email, user_id, phone, type, status, user_wallet_balance, email_verified_at, created_at, updated_at };
    }

    const rows = adminData?.data?.map(dt => {
        return createData(dt.id, dt.name, dt.email, dt.nice_id, dt.phone, dt.type, dt.status, dt.user_wallet_balance, dt?.email_verified_at?.slice(0, -8), dt?.created_at?.slice(0, -8), dt?.updated_at?.slice(0, -8))
    })

    const handleAction = (e) => {
        const status = e.target.value;
        const id = e.target.getAttribute('user-id');
        const name = e.target.getAttribute('user-name');
        const mobile = e.target.getAttribute('user-mobile');
        const userObj = {
            id,
            name,
            mobile,
            status,
            token: cookies?.token
        }
        setUserStatus(status);
        setUserDetail(userObj);
        setShowModal(true);
    }
    useEffect(() => {
        showModal && document.getElementById("warningModal")?.classList.add("active")

    }, [showModal])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleCloseModal = () => {
        setShowModal(false);
        document.getElementById("warningModal")?.classList.remove("active")
    }
    const handleSubmit = () => {
        setIsLoading(true);
        dispatch(updateUser(userDetail))
            .then(res => {
                if (typeof res?.type !== "undefined" && res.type.toString() === "users/updateuser/fulfilled") {
                    setShowModal(false);
                    setIsLoading(false);
                    document.getElementById("warningModal")?.classList.remove("active");
                    fetch_all_orders();
                } else if (typeof res?.type !== "undefined" && res.type.toString() === "users/updateuser/rejected") {
                    dispatch(setMessage({
                        status: true,
                        message: res?.payload?.response?.data?.message,
                        type: "error"
                    }));
                }
            })
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };
    return (
        <>
            {showModal && <div className="modal" id='warningModal' tabindex="-1">
                <div className="modal-dialog" >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fill-success">Modal title</h5>
                            <button type="button" className="btn-close" onClick={handleCloseModal} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body modal-body">
                            <p>{`Are you sure you want to ${userStatus === "blocked" ? 'block' : 're-activate'} this user?`}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={handleCloseModal} data-bs-dismiss="modal">Close</button>
                            <button type="button" onClick={handleSubmit} className="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div>
            </div>}
            {isLoading &&
                <Box className="homeLoader" sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>}
            <Paper className='allUsers-paper' sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer className='allUsers-table-container' sx={{ maxHeight: '70%' }}>
                    <Table className='allUsers-table' stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, backgroundColor: "#495057", color: "#fff" }}
                                    >
                                        {column.label.toUpperCase()}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className='allUsers-body'>
                            {rows?.map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row?.code}>
                                            {row?.id === 0 ?
                                                <TableCell colSpan={10}>
                                                    No Records Found
                                                </TableCell>
                                                : columns.map((column) => {
                                                    const value = row[column.id];
                                                    if (column.id.toString() === "action") {
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {/* <button type='button' onClick={handleAction} className={`btn btn-${buttonClass}`}>{buttonName}</button> */}
                                                                {/* <select onChange={handleAction} value={column.status} className='form-control'>
                                                                <option value="active">Active</option>
                                                                <option value="blocked">Blocked</option>
                                                            </select> */}
                                                                <button type="button" className={`btn btn-${row?.status === "active" ? "success" : "danger"}`} user-id={row?.id} user-name={row?.name} user-mobile={row?.phone} onClick={handleAction} value={`${row?.status === "active" ? "blocked" : "active"}`}>
                                                                    {row?.status?.toUpperCase()}
                                                                </button>
                                                            </TableCell>)
                                                    } else {
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {column.format && typeof value === 'number'
                                                                    ? column.format(value)
                                                                    : (column.id==="created_at" ||column.id === "updated_at" || column.id === "email_verified_at") ? convertTime(value): value}
                                                            </TableCell>
                                                        );
                                                    }
                                                })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                className='allUsersTablePagination'
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={adminData?.total > 0 ? adminData?.total : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    style={{ backgroundColor: "#495057" }}
                />
            </Paper>
        </>
    )
}

export default AllUsers