import { createSlice } from '@reduxjs/toolkit';

const initialState = 
  {
    orders: []
  };

const selectedOrdersSlice = createSlice({
    name: 'selectedorders',
    initialState,
    reducers:{
        addSelectedOrders:(state,action)=>{
            state.orders.push(action.payload)
        },
        deleteOrders:(state,action)=>{
            const id = action.payload;
            state.orders = state.orders.filter(st => Number(st) !== Number(id));
        }
    }
});
export const { addSelectedOrders, deleteOrders} = selectedOrdersSlice.actions
export default selectedOrdersSlice.reducer;
