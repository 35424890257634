import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import data from "../../../config.json";


const updateUser = createAsyncThunk(
    'users/updateuser',
    async (postData, thunkAPI) => {
        console.log('[updateUser] postData', postData !== undefined ? postData : '');
        try {
            const url = data?.api?.admin_update_user_post;
            const token = typeof postData?.token !== "undefined" && "Bearer " + postData.token
            const config = {
                headers: {
                    Authorization: token,
                    "Access-Control-Allow-Origin": 'https://recyclebee.ca/api/admin/update-user',
                    "Access-Control-Allow-Headers": "Content-Type, Authorization",
                }
            };
            if (url) {
                const response = await axios.post(url, postData, config);
                console.log('[updateUser] response', response);
                if (response.status === 200 && response.data.status === true) {
                    return thunkAPI.fulfillWithValue(response.data.message);
                } else {
                    throw "something went wrong"
                }
            } else {
                throw "url not found"
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    },
)
export default updateUser;