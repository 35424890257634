import React,{useState, useEffect} from 'react';
import "./profile.css";
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { useSelector, useDispatch } from 'react-redux';
import getUserDetails from "../../store/thunk/user/userRequest"
import sendOtpToEmail from "../../store/thunk/verification/verifyEmailRequest";
import verifyEmail from "../../store/thunk/verification/verifyEmailOtpRequest";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies] = useCookies(['token']);
  const [snackbar, setSnackbar] = useState({ "status": false, "msg": "", Transition: Slide });
  const [showVerification, setShowVerification] = useState(false);
  const [updateUser, setUpdateUser] = useState(false);
  const users = useSelector(store=>store.users);
  const [user, setUser] = useState(users);
  console.log("user",user);
  const handleClick = () => {
    navigate("/home");
  }
  const handleVerify = (event) => {
    event.preventDefault();
    if (typeof cookies.token !== "undefined" && cookies.token && cookies.token.length > 40) {
      dispatch(sendOtpToEmail({
        token: cookies?.token
      })).then((res) => {
        if (typeof res?.type !== "undefined" && res.type.toString() === "users/verifyemail/fulfilled") {
          setShowVerification(true);
        }
      })
    }
  }
    
  const handleOtpVerification = () => {
    setUpdateUser(false);
    const verify_code = document.getElementById("verify_email").value;
    if(!verify_code){
      setSnackbar({ "status": true, "msg": "Please provide OTP", "type": "error" })
    }else{
      if (typeof cookies.token !== "undefined" && cookies.token && cookies.token.length > 40) {
        dispatch(verifyEmail({
          token: cookies?.token,
          otp: verify_code
        })).then((res) => {
          if (typeof res?.type !== "undefined" && res.type.toString() === "users/verifyemailotp/fulfilled") {
            setShowVerification(false);
            setUpdateUser(true);
          }
        })
      }
    }
  }
  const handleClose = () => {
    setSnackbar({ "status": false, "msg": "" })
  }
  useEffect(()=>{
    const splitUrl = window.location.href.split('/');
    const lastWord = splitUrl[splitUrl.length-1];
    if(lastWord.toString() === "profile"){
      console.log("profile triggered",cookies.token);
      if (typeof cookies.token == "undefined" || !cookies.token ) {
        navigate('/login');
      }
    }
    dispatch(getUserDetails({
      token: cookies?.token
    })).then((res)=>{
      console.log("res",res);
      setUser(res?.payload)
    })
  },[])
  useEffect(()=>{
    const splitUrl = window.location.href.split('/');
    const lastWord = splitUrl[splitUrl.length-1];
    if(lastWord.toString() === "profile"){
      console.log("profile triggered",cookies.token);
      if (typeof cookies.token == "undefined" || !cookies.token ) {
        navigate('/login');
      }
    }
    dispatch(getUserDetails({
      token: cookies?.token
    })).then((res)=>{
      setUser(res?.payload)
    })
  },[updateUser])

  if (typeof cookies.token == "undefined" || !cookies.token ) {
    navigate('/login');
  } else {
    return (
      <div className='profileDiv'>
        {/* <img className='profileImg' src='https://img.freepik.com/premium-photo/3d-rendering-profile-user-icon-symbols-background-blue-color-front-view_607106-10.jpg'/>
        <h1 className='profileText'>Profile</h1> */}
        <div className='profileTitle'>PROFILE</div>
        <Snackbar
                open={snackbar.status}
                autoHideDuration={3000}
                onClose={handleClose}
                TransitionComponent={snackbar.Transition}
            >
                <Alert
                    severity={snackbar.type}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbar.msg}
                </Alert>
            </Snackbar>
        <div className='profileBar'>
          <form className="profileForm">
            <div>
              <label>Name</label>
              <input type="text" className="form-control mt-1" name="name" placeholder="Full Name" value={user?.name} required disabled />
            </div>
            <div>
              <label>Email</label>
              <input type="email" className="form-control mt-1 profileEmail" name="email" placeholder="E-mail" value={user?.email} required disabled/>
              {(user?.email_verified_at === null && !showVerification) && <><p style={{color:"red"}}>Email is not verified, Please <a style={{color:"blue"}} onClick={handleVerify}>verify</a> </p></>}
              {(user?.email_verified_at !== null) && <p style={{color:"green"}}>Email is verified</p>}
            </div>
            {showVerification && <div>
              <input type="text" className="form-control mt-2" id='verify_email' name="verify_email" placeholder="Email OTP" required/>
              <button type='button' onClick={handleOtpVerification} className='btn btn-primary mt-2'>Verify OTP</button>
            </div>}
            <div>
              <label>Mobile No</label>
              <input type="number" className="form-control mt-1" name="mobile" placeholder="mobile no" value={user?.phone} required disabled/>
            </div>
            <div>
              <label>Pincode</label>
              <input type="text" className="form-control mt-1" name="pincode" placeholder="Pincode" value="" required disabled/>
            </div>
            {/* <div>
              <label>Password</label>
              <input type="password" className="form-control mt-1" name="pincode" placeholder="Pincode" value="" required disabled/>
            </div> */}
          </form>
        </div>
        {false && <button type='button' onClick={handleClick} className='btn btn-success profile-btn'>Update</button>}
      </div>
    )
  }
}

export default Profile