import React,{useEffect, useState} from 'react';
import "./forgotPassword.css";
import loginUser from "../../store/thunk/login/loginRequest";
import {getUser} from "../../store/thunk/login/loginSlice";
import {useDispatch,useSelector} from "react-redux";
import { useNavigate } from 'react-router-dom';
import {useCookies} from "react-cookie";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import forgotPassword from '../../store/thunk/forgotPassword/forgotPassword';
import changePassword from '../../store/thunk/forgotPassword/changePassword';

function ForgotPassword() {

    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [showSendOtp, setShowSendOtp] = useState(true);
    const [snackbar, setSnackbar] = useState({"status":false,"msg":"",Transition: Slide});
    const storeUser = useSelector(getUser)
    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const dispatch = useDispatch();
    const handleClose = ()=>{
      setSnackbar({"status":false,"msg":""})
    }
    const handleForgotPassword = ()=>{
      console.log("forgot password");
    }
    const handleEmail = (e)=>{
        setShowSendOtp(true);
      e.preventDefault()
      const userData = {
        "email": email
    }
      console.log('userData',userData);
      if(userData.email){
          dispatch(forgotPassword(userData))
          .then(res => {
            console.log("res", res);
            if(res.error){
              let message = res.payload.response.request.response;
              const parsedData = JSON.parse(message);
              console.log("message",parsedData);
              setSnackbar({ "status": true, "msg": parsedData.message, "type": "error" })
            }else if (typeof res?.type !== "undefined" && res.type.toString() === "users/forgotpassword/fulfilled") {
                setShowSendOtp(false);
            }
          })
      }else{
        setSnackbar({"status":true,"msg":"Please provide a valid email", "type":"error"})
      }
    }
    const handleSubmit = (e)=>{
      e.preventDefault()
      const userData = {
        "email":email,
        "password": password,
        "password_confirmation": confirmPassword,
        "otp": otp,
    }
      console.log('userData',userData);
      if(userData.password && userData.password_confirmation && userData.otp){
        if(userData.password === userData.password_confirmation){
          dispatch(changePassword(userData))
          .then(res => {
            console.log("res", res);
            if(res.error){
              let message = res.payload.response.request.response;
              const parsedData = JSON.parse(message);
              console.log("message",parsedData);
              setSnackbar({ "status": true, "msg": parsedData.message, "type": "error" })
            }else if (typeof res?.type !== "undefined" && res.type.toString() === "users/changepassword/fulfilled") {
                setSnackbar({"status":true,"msg":"Password changed successfully", "type":"success"})
                navigate('/login');
            }
          })
        }else{
          setSnackbar({"status":true,"msg":"Password doesn't match", "type":"info"})
        }
      }else{
        setSnackbar({"status":true,"msg":"Please provide inputs", "type":"error"})
      }
    }

  return (
    <div>
      <div className='LoginDiv'>
        <Snackbar
        open={snackbar.status}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionComponent={snackbar.Transition}
        >
          <Alert
          severity={snackbar.type}
          variant="filled"
          sx={{ width: '100%' }}
          >
            {snackbar.msg}
          </Alert>
        </Snackbar>
        <h1>Forgot Password</h1>
        <form className="loginForm">
          {showSendOtp && <><input type="email" className="form-control mb-3" name="email" onChange={(e)=>setEmail(e.target.value)} placeholder="Email" />
          <input type="button" className="btn btn-primary Login-btn" onClick={handleEmail} value="Send OTP" /></>}
          {!showSendOtp && <><input type="text" className="form-control mb-3" name="password" onChange={(e)=>setPassword(e.target.value)} placeholder="Password" />
          <input type="password" className="form-control mb-3" name="re-password" onChange={(e)=>setConfirmPassword(e.target.value)} placeholder="Confirm password" />
          <input type="text" className="form-control mb-3" name="otp" onChange={(e)=>setOtp(e.target.value)} placeholder="Email OTP" />
          <input type="button" className="btn btn-primary Login-btn" onClick={handleSubmit} value="Submit" /></>}
        </form>
      </div>
    </div>
  )
}

export default ForgotPassword