import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import data from "../../../../config.json";


export const fetchUserData = createAsyncThunk(
    'users/orderdetail',
    async (postData, thunkAPI) => {
        console.log('[fetchOrderDetailData] postData', postData !== undefined ? postData : '');
        try {
            const url = data?.api?.admin_post;
            console.log("url",url);
            const token = typeof postData?.token !== "undefined" && postData.token
            const config = {
                headers: { 
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin":  'https://recyclebee.ca/api/admin/fetch',
                    "Access-Control-Allow-Headers": "Content-Type, Authorization",
                }
            };
            const response = await axios.post(url,postData,config);
            console.log('[fetchOrderDetailData] response', response,typeof response.data.fetch_products );
            if(response.status === 200 && response.data.status === true && typeof response.data.fetch_products !== undefined){
              return thunkAPI.fulfillWithValue(response.data.data);
            }else{
              return thunkAPI.rejectWithValue(response.data.message);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    },
  );
export const fetchOrderDetailData = createAsyncThunk(
    'users/orderdetail',
    async (postData, thunkAPI) => {
        console.log('[fetchOrderDetailData] postData', postData !== undefined ? postData : '');
        try {
            const url = data?.api?.admin_order_detail_post;
            console.log("url",url);
            const token = typeof postData?.token !== "undefined" && postData.token
            const config = {
                headers: { 
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin":  'https://recyclebee.ca/api/admin/fetch',
                    "Access-Control-Allow-Headers": "Content-Type, Authorization",
                }
            };
            const response = await axios.post(url,postData,config);
            console.log('[fetchOrderDetailData] response', response,typeof response.data.fetch_products );
            if(response.status === 200 && response.data.status === true && typeof response.data.fetch_products !== undefined){
              return thunkAPI.fulfillWithValue(response.data.data);
            }else{
              return thunkAPI.rejectWithValue(response.data.message);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    },
  );