import { createSlice } from '@reduxjs/toolkit';
import {fetchOrderData} from './adminRequest';



const initialState = {
    data: [
        {
            "id": 0,
            "user_id": "",
            "currency": "",
            "payment_ref_id": "",
            "order_total_charged_amount": "",
            "payment_charge_id": "",
            "reward_redeem_mode": "",
            "payment_status": "",
            "order_address": "",
            "order_street": "",
            "order_city": "",
            "order_postal_code": "",
            "order_mobile": "",
            "updated_at": "",
            "created_at": "",
            "nice_id": "",
            "phone": "",
            "name": "",
            "email": "",
            "email_verified_at": "",
            "password": "",
            "user_wallet_balance": "",
            "remember_token": "",
            "type": ""
        }
    ],
    total: 0,
    isError: false
};
const updateadminList = (state, currentData) => {
    console.log("currentData",currentData);
    if(currentData?.fetch_users !== undefined){
        state.data = [ ...currentData?.fetch_users.users];
        state.total = currentData?.fetch_users.total
    }else if(currentData?.fetch_orders !== undefined){
        state.data = [ ...currentData?.fetch_orders.orders];
        state.total = currentData?.fetch_orders.total
    }
    return state
}

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrderData.pending, (state, action) => {
                state.isError = false
                state.loading = true
            })
            .addCase(fetchOrderData.fulfilled, (state, action) => {
                console.log('fetchOrderData.fulfilled', action);
                updateadminList(state, action.payload);
                state.isError = false
                state.loading = false
            })
            .addCase(fetchOrderData.rejected, (state, action) => {
                console.log('fetchOrderData.rejected', action);
                state.isError = true
                state.loading = false
                state.errorMessage = action.payload.message
            })
    },
});

export const getadmins = (state) => {
    return state.orders
}
export default ordersSlice.reducer;
