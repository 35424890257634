import React, { useState, useEffect, useRef, useMemo } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import "./userOrders.css";
import { fetchOrderDetailData } from '../../../store/thunk/admin/orderDetail/orderDetailRequest';
import { fetchOrderData } from '../../../store/thunk/admin/adminRequest';
import { styled } from '@mui/material/styles';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import updateUser from '../../../store/thunk/update-user/updateUserRequest';
import { setMessage } from '../../../store/thunk/error/errorMessage';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Moment from 'react-moment';
import manageOrderLinesData from '../../../store/thunk/admin/orderDetail/manageOrderDetail';
import CustomizedSteppers from '../../../components/stepper/Stepper';
import {addSelectedOrders , deleteOrders} from '../../../store/thunk/selectedOrders/selectedOrdersSlice';
import orderRouting from '../../../store/thunk/selectedOrders/orderRoutingRequest';



export const Row = (props) => {
    const { row, columns, setOrderCheckbox } = props;
    const [open, setOpen] = React.useState(false);
    const [orderDetail, setOrderDetail] = React.useState([]);
    const [cookies, removeCookie] = useCookies(['token']);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [status, setStatus] = useState("requested");
    const orders = useSelector(state => state.orders);
    const selectedOrders = useSelector(state => state.selectedorders);
    const [allOrders, setAllOrders] = useState([]);

    const ref = useRef();

    const orderDetailColumns = [
        { id: 'box', label: '', minWidth: 70, align: 'center' },
        { id: 'id', label: 'Id', minWidth: 70, align: 'center' },
        { id: 'product_image', label: 'Product Image', minWidth: 150, align: 'center' },
        { id: 'product_name', label: 'Product Name', minWidth: 150, align: 'center' },
        { id: 'product_quantity', label: 'Product Qty', minWidth: 170, align: 'center' },
        { id: 'product_status', label: 'Status', minWidth: 100, align: 'center' },
    ];

    const fetch_all_order_details = (order_id) => {
        setIsLoading(true);
        dispatch(fetchOrderDetailData({
            "order_id": order_id,
            token: cookies.token
        })).then((res) => {
            if (typeof res?.type !== "undefined" && res.type.toString() === "users/orderdetail/fulfilled") {
                setIsLoading(false);
                setOrderDetail(res?.payload);
            } else if (typeof res?.type !== "undefined" && res.type.toString() === "users/orderdetail/rejected") {
                dispatch(setMessage({
                    status: true,
                    message: res?.payload?.response?.data?.message,
                    type: "error"
                }));
                setIsLoading(false);
            }
        })
    }
    useEffect(() => {
    console.log("order details", orderDetail);
    }, [orderDetail])
    const handleCheckBoxHeader = (e) => {
        // e.preventDefault();
        // ref.current.getAttribute("input").setAttribute("checked",true);
        const status = e.target.checked;
        let newArr;
        if (status) {
            newArr = orderDetail.map(dt => {
                return { ...dt, checked: true };
            })
        } else {
            newArr = orderDetail.map(dt => {
                return { ...dt, checked: false };
            })
            setCheckAll(false)
        }
        setOrderDetail(newArr);
    }
    const handleCheckBoxBody = (e) => {
        // e.preventDefault();
        const status = e.target.checked;
        console.log(status);
        const id = e.currentTarget.getAttribute("data-orderDetailId");
        let newArr;
        if (status) {
            newArr = orderDetail.map(dt => {
                if (dt?.product_details_array?.id == id) {
                    return { ...dt, checked: true }
                } else {
                    return { ...dt }
                }
            })
        } else {
            newArr = orderDetail.map(dt => {
                if (dt?.product_details_array?.id == id) {
                    return { ...dt, checked: false }
                } else {
                    return { ...dt }
                }
            })
        }
        setOrderDetail(newArr);
    }
    const handleShowOrderDetails = (e) => {
        const order_id = e.currentTarget?.getAttribute("order-id");

        setOpen(!open);
        fetch_all_order_details(order_id);
    }
    const handleQtyChange = (e) => {

        const order_id = e.currentTarget?.getAttribute("order-id");
        const status = e.currentTarget?.getAttribute("status");

        const updatedVal = e.target.value;
        const newArr = orderDetail.map(dt => {
            if (dt?.product_details_array?.id == order_id) {
                return { ...dt, product_quantity: updatedVal }
            } else {
                return { ...dt }
            }
        });
        if (status === "approved") {
            setOrderDetail(newArr);
        } else {
            dispatch(setMessage({
                status: true,
                message: "Qty can't be updated at this stage",
                type: "error"
            }));
            return;
        }
    }

    const handleOrderCheckboxChange = (e) => {
        const status = e.target.checked;
        const id = e.currentTarget.getAttribute("order-id");
        let newArr;
        if (status) {
            dispatch(addSelectedOrders(Number(id)));
        }else{
            const allOrders = selectedOrders?.orders;
            console.log(id,allOrders, allOrders.indexOf(id));
            console.log("selectedOrders",selectedOrders);
            if(allOrders.indexOf(Number(id)) !== -1){
                dispatch(deleteOrders(Number(id)));
            }
        }
        // setOrderCheckbox(newArr);
    }
    const handleStatusChange = (e) => {
        setIsLoading(true);
        e.preventDefault();
        const changedVal = e.target.value;
        let newArr = [];
        let flag = false
        orderDetail.map(dt => {
            if (typeof dt.checked !== "undefined" && dt.checked !== false) {
                flag = true;
            }
        })
        if (flag) {
            setStatus(changedVal);
            const updateArray = orderDetail.filter(dt => dt.checked === true)
            const updatedObj = {
                "order_id": updateArray[0].order_id,
                token: cookies.token,
                "products":
                    updateArray.map(dt => {
                        return ({
                            "id": dt.id,
                            "qty": dt.product_quantity,
                            "status": changedVal
                        })
                    })
            }
            dispatch(manageOrderLinesData(updatedObj)).then((res) => {
                if (typeof res?.type !== "undefined" && res.type.toString() === "users/manageorderlines/fulfilled") {
                    setIsLoading(false);
                    setStatus("requested");
                    fetch_all_order_details(res.meta.arg.order_id);
                } else if (typeof res?.type !== "undefined" && res.type.toString() === "users/manageorderlines/rejected") {
                    dispatch(setMessage({
                        status: true,
                        message: res?.payload?.response?.data?.message,
                        type: "error"
                    }));
                    setIsLoading(false);
                }
            })
        } else {
            setIsLoading(false);
            setStatus("requested");
            dispatch(setMessage({
                status: true,
                message: "Please select atleast 1 order",
                type: "error"
            }));
        }

    }

    return (
        <>
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} hover role="checkbox" tabIndex={-1} key={row.code}>
                    {row?.id === 0 ?
                        <TableCell colSpan={10}>
                            No Records Found
                        </TableCell>
                        : columns.map((column) => {
                            const value = row[column.id];
                            if (column.id.toString() === "id") {
                                return (
                                    <TableCell>
                                        <Checkbox inputProps={{ 'order-id': row['order_id'] }} onChange={handleOrderCheckboxChange} />
                                    </TableCell>
                                )
                            }
                            if (column.id.toString() === "order_id") {
                                return (
                                    <>
                                        <TableCell key={column.id} align={column.align} style={{ padding: 0 }}>
                                            {value}
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                order-id={value}
                                                onClick={handleShowOrderDetails}
                                            >
                                                {open ? <KeyboardArrowUpIcon order-id={value} /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </TableCell>
                                    </>
                                )
                            } else {
                                return (
                                    <>
                                        <TableCell key={column.id} align={column.align} style={{ padding: 0 }}>
                                            {column.format && typeof value === 'number'
                                                ? column.format(value)
                                                : value}
                                        </TableCell>
                                    </>
                                );
                            }
                        })}
                </TableRow>
                {isLoading &&
                    <Box className="homeLoader" sx={{ display: 'flex', position: "fixed", padding: "2rem", backgroundColor: "#fff", top: "22rem", zIndex: "100", height: "10% !important", left: "40%" }}>
                        <CircularProgress />
                    </Box>}
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <div className='d-flex flex-row justify-content-between'>
                                    <Typography style={{ fontWeight: "700", fontSize: "16px" }} gutterBottom component="div">
                                        ORDER DETAILS:
                                    </Typography>
                                    <select style={{ color: "grey", backgroundColor: "#f8f9fa" }} onChange={handleStatusChange} value={status} className='custom-select form-control w-25'>
                                        <option value="requested">Requested</option>
                                        <option value="approved">Approved</option>
                                        <option value="processing">Processing</option>
                                        <option value="rejected">Rejected</option>
                                        <option value="completed">Completed</option>
                                    </select>
                                </div>
                                <Table className="w-100 mt-3" size="large" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            {orderDetailColumns.map((column) => {
                                                if (column.id.toString() === "box") {
                                                    return (
                                                        <TableCell key={column.id} align={column.align} style={{ padding: 0 }}>
                                                            <>
                                                                <Checkbox onChange={handleCheckBoxHeader} />
                                                            </>
                                                        </TableCell>)
                                                } else {
                                                    return (
                                                        <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, padding: 0, fontWeight: 700 }}>
                                                            {column.label.toUpperCase()}
                                                        </TableCell>
                                                    );
                                                }
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody >
                                        {orderDetail.length > 0 && orderDetail.map((row) => {
                                            return (
                                                <TableRow >
                                                    <TableCell align="center">
                                                        <Checkbox inputProps={{ 'data-orderDetailId': row?.product_details_array?.id }} checked={typeof row?.checked !== "undefined" ? row.checked : false} onChange={handleCheckBoxBody} />
                                                    </TableCell>
                                                    <TableCell component="th" align="center" scope="row">
                                                        {row?.product_details_array?.id}
                                                    </TableCell>
                                                    <TableCell align="center"><img width="30" height="30" src={row.product_details_array.product_image} /></TableCell>
                                                    <TableCell align="center">{row.product_details_array.product_name.toUpperCase()}</TableCell>
                                                    <TableCell align="right"><input order-id={row?.product_details_array?.id} status={row.product_status} type="text" className='form-control w-100' onChange={handleQtyChange} name="order-qty" value={row.product_quantity} /></TableCell>
                                                    <TableCell align="center">
                                                        {row.product_status.toUpperCase()}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <CustomizedSteppers data={row} />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        </>
    );
}
function UserOrders() {
    const [page, setPage] = React.useState(0);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [userDetail, setUserDetail] = useState({});
    const [cookies, removeCookie] = useCookies(['token']);
    const dispatch = useDispatch();
    const admin = useSelector(state => state.orders);
    const [adminData, setAdminData] = useState(admin);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [orderCheckbox, setOrderCheckbox] = React.useState([]);
    const selectedOrders = useSelector(state => state.selectedorders);




    const columns = [
        { id: 'id', label: '', minWidth: 20, align: 'center' },
        { id: 'order_id', label: 'order id', minWidth: 20, align: 'center' },
        { id: 'name', label: 'User Name', minWidth: 170 },
        {
            id: 'user_id',
            label: 'User Id',
            minWidth: 90,
            align: 'center'
        },
        { id: 'address', label: 'Address', minWidth: 150 },
        { id: 'city', label: 'city', minWidth: 100 },
        { id: 'mobile', label: 'mobile', minWidth: 100 },
        { id: 'postal_code', label: 'postal', minWidth: 140, align: "center" },
        { id: 'street', label: 'street', minWidth: 180 },
        // {
        //     id: 'phone',
        //     label: 'phone',
        //     minWidth: 100,
        //     align: 'right'
        // },
        // {
        //     id: 'type',
        //     label: 'Type',
        //     minWidth: 150,
        //     align: 'right'
        // },
        // {
        //     id: 'user_wallet_balance',
        //     label: 'User Wallet Balance',
        //     minWidth: 150,
        //     align: 'right'
        // },
        {
            id: 'reward_redeem_mode',
            label: 'Reward',
            minWidth: 100,
            align: 'center'
        },
        // {
        //     id: 'email_verified_at',
        //     label: 'Email Verified At',
        //     minWidth: 170,
        //     align: 'right',
        // },
        {
            id: 'created_at',
            label: 'Created At',
            minWidth: 200,
            align: 'right',
        },
        {
            id: 'updated_at',
            label: 'Updated At',
            minWidth: 200,
            align: 'right'
        },
        {
            id: 'action',
            label: 'Action',
            minWidth: 120,
            align: 'center'
        },
    ];
    function convertTime(value) {
        const convertedDate = <Moment subtract={{ hours: 3 }}>{value}</Moment>;
        return convertedDate
    }

    const fetch_all_orders = () => {
        setIsLoading(true);
        dispatch(fetchOrderData({
            "get": [
                {
                    "key": "fetch_orders",
                    "limit": rowsPerPage,
                    "offset": rowsPerPage * page
                }
            ],
            token: cookies.token
        })).then((res) => {
            if (typeof res?.type !== "undefined" && res.type.toString() === "users/orders/fulfilled") {
                setIsLoading(false);
            } else if (typeof res?.type !== "undefined" && res.type.toString() === "users/orders/rejected" && res?.payload?.response?.status === 401) {
                dispatch(setMessage({
                    status: true,
                    message: res?.payload?.response?.data?.message,
                    type: "error"
                }));
                setIsLoading(false);
                localStorage.clear();
                removeCookie('token', '/');
                navigate('/login');


                window.location.reload();
            } else if (typeof res?.type !== "undefined" && res.type.toString() === "users/orders/rejected") {
                setIsLoading(false);
                dispatch(setMessage({
                    status: true,
                    message: res?.payload?.response?.data?.message,
                    type: "error"
                }));

            }
        })
    }
    useEffect(() => {
        if (typeof cookies.token !== "undefined" && cookies.token !== "") {
            fetch_all_orders();
        } else {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        fetch_all_orders();
    }, [rowsPerPage, page]);

    useEffect(() => {
        setAdminData(admin);
    }, [admin]);

    function createData(
        order_id,
        name,
        user_id,
        address,
        city,
        mobile,
        postal_code,
        street,
        // phone,
        // type,
        status,
        // user_wallet_balance,
        reward_redeem_mode,
        // email_verified_at,
        created_at,
        updated_at,
        action
    ) {
        // const density = population / size;
        return {
            order_id,
            name,
            user_id,
            address,
            city,
            mobile,
            postal_code,
            street,
            // phone,
            // type,
            status,
            // user_wallet_balance,
            reward_redeem_mode,
            // email_verified_at,
            created_at,
            updated_at,
            action,
            history: [
                {
                    date: '2020-01-05',
                    customerId: '11091700',
                    amount: 3,
                },
                {
                    date: '2020-01-02',
                    customerId: 'Anonymous',
                    amount: 1,
                },
            ],
        };
    }

    const rows = adminData?.data?.map(dt => {
        return createData(
            dt.order_id,
            dt.customer_name,
            dt.nice_id,
            dt.order_address,
            dt.order_city,
            dt.order_mobile,
            dt.order_postal_code,
            dt.order_street,
            // dt.phone,
            // dt.type,
            dt.payment_status,
            // dt.user_wallet_balance,
            dt.reward_redeem_mode,
            // dt?.email_verified_at?.slice(0, -8),
            convertTime(dt?.created_at),
            convertTime(dt?.updated_at),
            dt.payment_status.toUpperCase()
        )
    })


    const handleAction = (e) => {
        setShowModal(true);
        const status = e.target.value;
        const id = e.target.getAttribute('user-id');
        const name = e.target.getAttribute('user-name');
        const mobile = e.target.getAttribute('user-mobile');
        const userObj = {
            id,
            name,
            mobile,
            status,
            token: cookies?.token
        }
        setUserDetail(userObj);
    }
    useEffect(() => {
        showModal &&
            document.getElementById("warningModal")?.classList.add("active");
    }, [showModal])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleCloseModal = () => {
        setShowModal(false);
        document.getElementById("warningModal")?.classList.remove("active")
    }
    const handleSubmit = () => {
        dispatch(updateUser(userDetail))
            .then(res => {
                if (typeof res?.type !== "undefined" && res.type.toString() === "users/updateuser/fulfilled") {
                    setShowModal(false);
                    document.getElementById("warningModal")?.classList.remove("active");
                } else if (typeof res?.type !== "undefined" && res.type.toString() === "users/updateuser/rejected") {
                    dispatch(setMessage({
                        status: true,
                        message: res?.payload?.response?.data?.message,
                        type: "error"
                    }));
                }
            })
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const handleRoute = (e)=>{
        setIsLoading(true);
        e.preventDefault();
        console.log("selectedOrders", selectedOrders);
        if (selectedOrders?.orders.length > 0 ) {
            setIsLoading(false);
           dispatch(orderRouting({...selectedOrders, token : cookies.token}))
           .then(res=>{
            if (typeof res?.type !== "undefined" && res.type.toString() === "users/ordersrouting/fulfilled") {
                setIsLoading(false);
                console.log("res",res);
                if(typeof res?.payload?.routeUrl !== "undefined" && res?.payload?.routeUrl){
                    let url = res?.payload?.routeUrl;
                    let strWindowFeatures = "location=yes,fullscreen=yes,scrollbars=yes,status=yes";
                    window.open(url,"_blank", strWindowFeatures);
                }else{
                    dispatch(setMessage({
                        status: true,
                        message: "something went wrong!",
                        type: "error"
                    }));
                }
            } 
            else if (typeof res?.type !== "undefined" && res.type.toString() === "users/ordersrouting/rejected" && res?.payload?.response?.status === 401) {
                dispatch(setMessage({
                    status: true,
                    message: res?.payload?.response?.data?.message,
                    type: "error"
                }));
                setIsLoading(false);
                localStorage.clear();
                removeCookie('token', '/');
                navigate('/login');
                window.location.reload();
            }
            else if (typeof res?.type !== "undefined" && res.type.toString() === "users/ordersrouting/rejected") {
                dispatch(setMessage({
                    status: true,
                    message: res?.payload?.response?.data?.message,
                    type: "error"
                }));
            }
           })
        } else {
            setIsLoading(false);
            dispatch(setMessage({
                status: true,
                message: "Please select atleast 1 order",
                type: "error"
            }));
        }
    }

    const handleDateFilter = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const fromDate = document.getElementById("orderFrom").value;
        const toDate = document.getElementById("orderTo").value;
        if (!fromDate || !toDate) {
            dispatch(setMessage({
                status: true,
                message: "Please select Input",
                type: "error"
            }));
        } else {
            dispatch(fetchOrderData({
                "get": [
                    {
                        "key": "fetch_orders",
                        "limit": rowsPerPage,
                        "offset": rowsPerPage * page,
                        "fromDate": new Date(fromDate + " 00:00").toISOString(),
                        "toDate": new Date(toDate + " 23:59").toISOString()
                    }
                ],
                token: cookies.token
            })).then((res) => {
                if (typeof res?.type !== "undefined" && res.type.toString() === "users/orders/fulfilled") {
                    setIsLoading(false);
                } else if (typeof res?.type !== "undefined" && res.type.toString() === "users/orders/rejected") {
                    dispatch(setMessage({
                        status: true,
                        message: res?.payload?.response?.data?.message,
                        type: "error"
                    }));
                    setIsLoading(false);
                    localStorage.clear();
                    removeCookie('token', '/');
                    navigate('/login');
                    window.location.reload();
                }
            })
        }
    }
    return (
        <>
            {showModal && <div class="modal" id='warningModal' tabindex="-1">
                <div class="modal-dialog" >
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title fill-success">Modal title</h5>
                            <button type="button" class="btn-close" onClick={handleCloseModal} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body modal-body">
                            <p>Are you sure you want to block this user?</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" onClick={handleCloseModal} data-bs-dismiss="modal">Close</button>
                            <button type="button" onClick={handleSubmit} class="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div>
            </div>}
            {isLoading &&
                <Box className="homeLoader" sx={{ display: 'flex', position: "relative", zIndex: "100" }}>
                    <CircularProgress />
                </Box>}
            <Paper className='userOrder-paper' sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer className='userOrder-table-container' sx={{ maxHeight: '70%' }} component={Paper}>
                    <Table className='userOrder-table' stickyHeader aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, backgroundColor: "#495057", color: "#fff", paddingRight: "16px" }}
                                    >
                                        {column.label.toUpperCase()}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className='userOrder-body'>
                            <TableRow align="center" style={{ backgroundColor: "#e9ecef" }} colSpan={12}>
                                <TableCell colSpan={13}>
                                    <div className='d-flex w-50 flex-row justify-content-between align-items-center' >
                                        <span className='w-25'>
                                            <strong>From:</strong>
                                        </span>
                                        <input type='date' id='orderFrom' className='form-control w-100' />
                                        <strong className='mx-3'>To:</strong>
                                        <input type='date' id='orderTo' className='form-control w-100 mx-3' />
                                        <button className='btn btn-primary' onClick={handleDateFilter}><span style={{ fontFamily: "system-ui" }}>Filter</span></button>
                                        <button className='btn btn-success w-50 mx-4' onClick={handleRoute}><span style={{ fontFamily: "system-ui" }}>Get route</span></button>
                                        
                                    </div>
                                </TableCell>
                            </TableRow>
                            {rows?.map((row) => {
                                return <Row key={row.order_id} row={row} setOrderCheckbox={setAdminData} columns={columns} />
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className='userOrder-pagination-div'
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={adminData?.total > 0 ? adminData?.total : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    style={{ backgroundColor: "#495057" }}
                />
            </Paper>
        </>
    )
}

export default UserOrders;