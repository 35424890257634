import React, { useEffect, useState } from 'react';
import "./orderDetails.css";
import dustbin from "../../public/images/dustbin.png";
import Chip from '@mui/material/Chip';
import OrderDetailCard from '../../components/card/orderDetailCard/OrderDetailCard';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from "react-cookie";
import getOrderById from '../../store/thunk/orderDetails/orderDetailRequest';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function OrderDetails(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const orderDet = useSelector(state => state.orderDetail);
    const [orderDetails, setOrderDetails] = useState(orderDet.data);
    const [cookies] = useCookies(['token']);
    const param = useParams();
    const [isLoading, setIsLoading] = useState(true);

    const handleClick = () => {
        navigate('/orders');
    }
    console.log("orderDet", orderDet, "orderDetails", orderDetails);
    useEffect(() => {
        setOrderDetails(orderDet.data);
    }, [orderDet])

    useEffect(() => {
        if (typeof cookies.token !== "undefined" && cookies.token && cookies.token.length > 40) {
            dispatch(getOrderById({
                token: cookies?.token,
                order_id: param?.order_id
            })).then((res) => {
                setIsLoading(false);
            })
        }
    }, []);

    return (
        <div>
            <div className='d-flex flex-row orderDetailHeader m-4'>
                <ArrowBackIosIcon className='orderCardArrow' onClick={handleClick} sx={{ color: "green", marginRight: "10px" }} />
                <div className='orderDetailId text-end ml-4'>Order#:{orderDetails && orderDetails[0]?.order_id}</div>
            </div>

            {!isLoading ? orderDetails.length > 0 && orderDetails.map((dt => {
                return <OrderDetailCard order_detail={dt} />
            })) :
                <Box className="homeLoader" sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            }
        </div>
    )
}

export default OrderDetails