import React, { useRef } from 'react'
import './bottomNav.css';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link } from 'react-router-dom';

function BottomNav() {
    const ref = useRef();
    return (
        <div ref={ref} className='bottomNav align-center'>
            <div className='bottomNav__item'>
                <Link to='/home'>
                    <PeopleIcon className='Flex-item' sx={{ fontSize: 25 ,color: 'white' }} />
                    <p>Users</p>
                </Link>
            </div>
            <div className='bottomNav__item'>
                <Link to='/orders'>
                    <ShoppingCartIcon className='Flex-item' sx={{ fontSize: 25 , color: 'white' }} />
                    <p>Orders</p>
                </Link>
            </div>
            <div className='bottomNav__item'>
                <Link to="/account">
                    <AccountBalanceIcon className='Flex-item' sx={{ fontSize: 25, color: 'white' }} />
                    <p>Account</p>
                </Link>
            </div>
            <div className='bottomNav__item'>
                <Link to="/wallet">
                    <AccountBalanceWalletIcon className='Flex-item' sx={{ fontSize: 25, color: 'white' }} />
                    <p>Wallet</p>
                </Link>
            </div>
            <div className='bottomNav__item'>
                <Link to="/profile">
                    <AccountCircleIcon className='Flex-item' sx={{ fontSize: 25 , color: 'white' }} />
                    <p>Profile</p>
                </Link>
            </div>
        </div>
    )
}

export default BottomNav