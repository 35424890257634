import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import aboutus from '../../../public/images/information.png';
import login from '../../../public/images/login.jpg';
import bee from '../../../public/images/bee.png';
import { Link } from 'react-router-dom';
import './header.css';
import Collapse from '@mui/material/Collapse';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import logoutUser from '../../../store/thunk/logout/logoutRequest';
import getUserDetails from '../../../store/thunk/user/userRequest';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PeopleIcon from '@material-ui/icons/People';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const drawerWidth = 240;



const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function Header() {
  const user = useSelector(store => store.user);
  const [showUserDetail, setShowUserDetail] = React.useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const token = (typeof cookies?.token !== "undefined" && cookies.token) ? cookies.token : '';
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [isCollapse, setIsCollapse] = React.useState(false);
  const cart = useSelector(state => state.cart);
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutUser({
      token
    })).then((res) => {
      // if(res?.data?.status === true){
      localStorage.clear();
      removeCookie('token', '/');
      navigate('/login');
      window.location.reload();
      // }
    })
  }
  React.useEffect(() => {
    if (user && user?.data?.name !== "name" && user?.data?.Phone !== "9998887774") {
      setShowUserDetail(true);
    }
  }, [user])
  React.useEffect(() => {
    dispatch(getUserDetails({
      token: cookies?.token
    })).then((res) => {
    })
  }, [])
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleCollapse = () => {
    setIsCollapse(!isCollapse);
  };
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <div>
      <CssBaseline />
      <AppBar position="relative" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            <div className='d-flex flex-row align-items-center'>
              <Link to="/home" style={{ color: "#fff" }}><div>Recycle <img style={{ position: "relative", top: "-7px" }} src={bee} width={20} height={20} /></div></Link>

            </div>
          </Typography>
          <div className="userLogo">
            {/* {false && <img src='https://e1.pngegg.com/pngimages/258/65/png-clipart-cody-christian-man-s-face.png' width={40} height={40} />} */}
            {/* <Link to='/cart'> */}
            {/* {true && <PersonAddIcon sx={{ fontSize: 35, color: 'white' }} />} */}
            {/* <div style={{display:"flex",flexDirection:"row"}}>
                <ShoppingCartIcon sx={{ fontSize: 25, color: 'white' }}/>
                <div style={{color:"#ffc107", position:"relative", bottom:"10px", fontWeight:"700",fontFamily:"sans-sarif"}}>{cart?.data?.length ? cart?.data?.length - 1 : 0}</div>
              </div>
            </Link> */}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        // allowSwipeInChildren={true}
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      >
        
        <DrawerHeader>
          {showUserDetail && <div className='d-flex flex-column justify-content-between userHeader' style={{ color: "#6d747b", textAlign: "left", width: "100%" }}><div>{user?.data?.name?.charAt(0).toUpperCase() + user?.data?.name.slice(1)}</div>
            <div style={{ fontSize: "13px", fontSize: "13px", fontFamily: "system-ui", color: "#4b7387" }}>UID : {user?.data?.nice_id}</div></div>}
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>

        <Divider />
        <List>
          {/* <Link to="/about-us">
            <ListItem key='About Us' disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <img width={25} height={25} src={aboutus}/>
                </ListItemIcon>
                <ListItemText primary='About Us' />
              </ListItemButton>
            </ListItem>
          </Link> */}
          {token === "" ? <Link to="/login">
            <ListItem key="Login" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <img width={25} height={25} src={login} />
                </ListItemIcon>
                <ListItemText primary="Login" />
              </ListItemButton>
            </ListItem>
          </Link> :
            <Link onClick={logout}>
              <ListItem key="Logout" disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <img width={25} height={25} src={login} />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItemButton>
              </ListItem>
            </Link>}
          <Divider />
          <List >
            <ListItem disablePadding sx={{ display: "block" }} onClick={handleCollapse}>
              <ListItemButton>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
                {isCollapse ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse in={isCollapse} timeout="auto" unmountOnExit>
              {['Orders', 'Users'].map((text, index) => (
                <Link to={(text === 'Orders') ? '/report/orders': '/report/users'}>
                  <ListItem key={text} disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        {index % 2 === 0 ? <ShoppingCartIcon /> : <PeopleIcon />}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </Collapse>
          </List>
        </List>
      </Drawer >
      </div>
  );
}