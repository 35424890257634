import React, { useEffect, useState } from 'react';
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';
import refresh from "../../public/images/refresh-button.png"
import "./wallet.css";
import { useSelector, useDispatch } from 'react-redux';
import getWalletDetails from '../../store/thunk/wallet/walletRequest';
import WalletCard from '../../components/card/walletCard/WalletCard';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


function Wallet() {
  const [cookies] = useCookies(['token']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wallet = useSelector(store => store.wallet);
  const [walletData, setWalletData] = useState(wallet);
  const [totalBal, setTotalBal] = useState(0);
  const [pendingBal, setPendingBal] = useState(0);
  const [cashBal, setCashBal] = useState(0);
  const [showLoading, setShowLoading] = useState(true);


  useEffect(() => {
    const splitUrl = window.location.href.split('/');
    const lastWord = splitUrl[splitUrl.length - 1];
    if (lastWord.toString() === "wallet") {
      if (typeof cookies.token == "undefined" || cookies.token == 0) {
        navigate('/login');
      }
    }
    dispatch(getWalletDetails({
      token: cookies?.token
    })).then(res => {
      if (typeof res?.type !== "undefined" && res.type.toString() === "users/wallet/fulfilled") {
        setShowLoading(false);
      }
    })
  }, []);
  useEffect(()=>{
    let TotalPendingRewards = 0;
    const notApproved = walletData.data.filter(dt => (dt.product_status !== "approved" && dt.reward_redeem_mode !== "cash"));
    (notApproved && notApproved.length > 0) && notApproved.map(dt => {
      TotalPendingRewards = TotalPendingRewards + dt.total_reward_point
    })
    setPendingBal(TotalPendingRewards);
    let totalrewards = 0;
    const approved = walletData.data.filter(dt => (dt.product_status === "approved" && dt.reward_redeem_mode !== "cash"));
    (approved && approved.length > 0) && approved.map(dt => {
      totalrewards = totalrewards + dt.total_reward_point
    })
    setTotalBal(totalrewards);
    let totalcash = 0;
    const cashData = walletData.data.filter(dt => dt.reward_redeem_mode === "cash");
    (cashData && cashData.length > 0) && cashData.map(dt => {
      totalcash = totalcash + dt.total_reward_point
    })
    setCashBal(totalcash);
  },[walletData])

  useEffect(() => {
    setWalletData(wallet);
  }, [wallet]);

  const handleRefresh = () => {
    setShowLoading(true);
    dispatch(getWalletDetails({
      token: cookies?.token
    })).then(res => {
      if (typeof res?.type !== "undefined" && res.type.toString() === "users/wallet/fulfilled") {
        setShowLoading(false);
      }

    })
  }
  if (typeof cookies.token === "undefined" || !cookies.token || cookies.token.length < 40) {
    navigate('/login');
  } else {
    return (
      <div>
        <div className='walletDiv'>
          <div className='d-flex flex-column balanceDiv'>
            <div>Total Balance</div>
            <div>{totalBal}</div>
          </div>
          <div className='d-flex flex-row align-center pendingBalDiv' style={{ color: "#fff" }}>
            <div className='d-flex flex-row align-center '>
              <div>Pending : </div>
              <div style={{ paddingLeft: "10px" }}> {pendingBal}</div>
            </div>
            <div style={{position: "absolute",right: "0",marginRight: "1rem"}} className='d-flex flex-row align-center  mr-4'>
              <div>Cash : </div>
              <div style={{ paddingLeft: "10px" }}> {cashBal} $</div>
            </div>
          </div>
          </div>
        <div style={{ display: "Flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}><h1 className='pt-md-2 pb-1 pl-1 transaction' style={{ fontFamily: "sarif", color: "grey" }}>Transactions</h1><img onClick={handleRefresh} className='mt-md-2 mb-3' style={{ marginRight: "1rem" }} width={20} height={20} src={refresh} /></div>
        {(walletData?.data?.length < 1 || walletData?.data[0]?.id === 0) && <div className='pt-md-2 pb-1 pl-1 transaction' style={{ fontFamily: "sarif", color: "grey" }}>No transaction history available</div>}
        <div className='walletCardDiv'>
          {!showLoading ? (walletData?.data?.length > 0 && walletData.data[0].id !== 0) && walletData.data.map(dt => {
            return <WalletCard order={dt} />
          }) :
            <Box className="homeLoader" sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          }
        </div>
      </div>
    )
  }
}

export default Wallet